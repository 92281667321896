var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { width: "1200px", margin: "0 auto" } }, [
    _c(
      "div",
      { staticStyle: { margin: "20px 0" } },
      [
        _c(
          "el-breadcrumb",
          { attrs: { "separator-class": "el-icon-arrow-right" } },
          [
            _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
              _vm._v("首页"),
            ]),
            _c("el-breadcrumb-item", [_vm._v("全部产品")]),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticStyle: { background: "#fff", padding: "20px" } },
      [
        _c("FilterCondition", {
          attrs: {
            title: "品种",
            data: _vm.filterConditionData,
            width: "60px",
          },
          on: { "on-go": _vm.go },
        }),
        _c("div", { staticStyle: { display: "flex" } }, [
          _c(
            "div",
            {
              staticStyle: {
                width: "64px",
                "text-align": "justify",
                "text-align-last": "justify",
                "line-height": "26px",
              },
            },
            [_vm._v(" 其他 ")]
          ),
          _c(
            "span",
            { staticStyle: { "margin-left": "1px", "line-height": "26px" } },
            [_vm._v("：")]
          ),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "items-align": "center",
                "font-size": "14px",
              },
            },
            [
              _c(
                "div",
                { staticStyle: { margin: "0 20px 0 10px" } },
                [
                  _vm._v(" 规格： "),
                  _c("el-input", {
                    staticStyle: {
                      outline: "none",
                      height: "10px",
                      width: "120px",
                    },
                    attrs: { type: "text", size: "mini" },
                    model: {
                      value: _vm.option.spec,
                      callback: function ($$v) {
                        _vm.$set(_vm.option, "spec", $$v)
                      },
                      expression: "option.spec",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticStyle: { "margin-right": "20px" } }, [
                _vm._v("钢厂："),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.option.manufacturer,
                      expression: "option.manufacturer",
                    },
                  ],
                  staticStyle: {
                    outline: "none",
                    height: "18px",
                    width: "120px",
                  },
                  attrs: { type: "text" },
                  domProps: { value: _vm.option.manufacturer },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.option, "manufacturer", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("div", { staticStyle: { "margin-right": "20px" } }, [
                _vm._v("供应商："),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.option.clientName,
                      expression: "option.clientName",
                    },
                  ],
                  staticStyle: {
                    outline: "none",
                    height: "18px",
                    width: "120px",
                  },
                  attrs: { type: "text" },
                  domProps: { value: _vm.option.clientName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.option, "clientName", $event.target.value)
                    },
                  },
                }),
              ]),
            ]
          ),
          _c(
            "button",
            {
              staticStyle: { background: "#fff", border: "1px solid #333" },
              on: { click: _vm.optionChangeGetList },
            },
            [_vm._v(" 搜索 ")]
          ),
        ]),
      ],
      1
    ),
    _c("div", [
      _c(
        "div",
        { staticStyle: { padding: "20px 0" } },
        [
          _vm._v(" 筛选： "),
          _c(
            "el-select",
            {
              staticStyle: { "margin-right": "20px" },
              attrs: { placeholder: "价格低的排前", size: "small" },
              on: { change: _vm.optionChangeGetList },
              model: {
                value: _vm.option.moneyOrder,
                callback: function ($$v) {
                  _vm.$set(_vm.option, "moneyOrder", $$v)
                },
                expression: "option.moneyOrder",
              },
            },
            _vm._l(_vm.optionsMoney, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              attrs: { placeholder: "报价时间筛选", size: "small" },
              on: { change: _vm.optionChangeGetList },
              model: {
                value: _vm.option.timeOrder,
                callback: function ($$v) {
                  _vm.$set(_vm.option, "timeOrder", $$v)
                },
                expression: "option.timeOrder",
              },
            },
            _vm._l(_vm.optionsTime, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _vm.productList.length !== 0
            ? [
                _c(
                  "div",
                  { staticClass: "goods-box" },
                  _vm._l(_vm.productList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: item.Id,
                        staticClass: "goods-item",
                        class: {
                          noMarign: index == 4 || index == 9 ? true : false,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.skip(item.id)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "img-box" }, [
                          _c("img", { attrs: { src: item.picUrl } }),
                        ]),
                        _c("p", { staticClass: "name" }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                        _c("p", { staticClass: "spec" }, [
                          _vm._v(_vm._s(item.material)),
                        ]),
                        item.isLvdingPrice
                          ? _c("p", { staticClass: "price" }, [
                              _c("span", [_vm._v("¥")]),
                              _vm._v(
                                _vm._s(
                                  isNaN(item.processCharge)
                                    ? _vm.lvDingPrice.taxPrice
                                    : _vm.lvDingPrice.taxPrice +
                                        item.processCharge
                                )
                              ),
                            ])
                          : _c("p", { staticClass: "price" }, [
                              _c("span", [_vm._v("¥")]),
                              _vm._v(_vm._s(item.retailPrice)),
                            ]),
                      ]
                    )
                  }),
                  0
                ),
              ]
            : _c("NoDataFound"),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "pager" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "prev, pager, next",
              total: _vm.option.total,
              "page-size": _vm.option.pageSize,
              "current-page": _vm.option.currentPage,
            },
            on: { "current-change": _vm.handleCurrentChange },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }