<template>
  <div style="width:1200px;margin:0 auto">
    <div style="margin:20px 0">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item>
        <el-breadcrumb-item>全部产品</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div style="background:#fff;padding:20px">
      <FilterCondition title="品种" :data="filterConditionData" @on-go="go" width="60px"></FilterCondition>
      <div style="display: flex">
        <div style="width: 64px;text-align: justify;text-align-last: justify;line-height:26px">
          其他
        </div>
        <span style="margin-left:1px;line-height:26px">：</span>
        <div style="display: flex; items-align: center; font-size: 14px">
          <div style="margin:0 20px 0 10px">
            规格：
            <el-input v-model="option.spec" type="text" size="mini" style="outline: none; height: 10px; width: 120px"></el-input>
          </div>
          <!--<div style="margin-right: 20px">-->
          <!--  厚度/口径：-->
          <!--  <input type="text" v-model="input.min" style="outline: none; height: 18px; width: 40px" />-->
          <!--  - <input type="text" v-model="input.max" style="outline: none; height: 18px; width: 40px" />-->
          <!--</div>-->
          <!--<div style="margin-right: 20px">-->
          <!--  宽度：<input type="text" v-model="input.minWidth" style="outline: none; height: 18px; width: 40px" />-->
          <!--  - <input type="text" v-model="input.maxWidth" style="outline: none; height: 18px; width: 40px" />-->
          <!--</div>-->
          <div style="margin-right: 20px">钢厂：<input type="text" v-model="option.manufacturer" style="outline: none; height: 18px; width: 120px" /></div>
          <div style="margin-right: 20px">供应商：<input type="text" v-model="option.clientName" style="outline: none; height: 18px; width: 120px" /></div>
        </div>
        <button style="background: #fff; border: 1px solid #333" @click="optionChangeGetList">
          搜索
        </button>
      </div>
    </div>

    <div>
      <div style="padding: 20px 0">
        筛选：
        <el-select v-model="option.moneyOrder" placeholder="价格低的排前" style="margin-right: 20px" size="small" @change="optionChangeGetList">
          <el-option v-for="item in optionsMoney" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
        <el-select v-model="option.timeOrder" placeholder="报价时间筛选" size="small" @change="optionChangeGetList">
          <el-option v-for="item in optionsTime" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </div>

      <div>
        <template v-if="productList.length !== 0">
          <div class="goods-box">
            <div class="goods-item" :class="{noMarign: index == 4 || index == 9 ? true : false}" v-for="(item, index) in productList" :key="item.Id" @click="skip(item.id)">
              <div class="img-box">
                <img :src="item.picUrl" />
              </div>
              <p class="name">{{ item.name }}</p>
              <p class="spec">{{ item.material }}</p>
              <p class="price" v-if="item.isLvdingPrice"><span>¥</span>{{ isNaN(item.processCharge) ? lvDingPrice.taxPrice : lvDingPrice.taxPrice + item.processCharge }}</p>
              <p class="price" v-else><span>¥</span>{{ item.retailPrice }}</p>
            </div>
          </div>
        </template>
        <NoDataFound v-else></NoDataFound>
      </div>
      <!-- <div class="content">
                <template v-if="productList.length !== 0">
                    <div class="box" v-for="item in productList" :key="item.id">
                        <router-link :to="'/home/goodsDetails?id=' + item.id">
                            <div class="box-in">
                                <div class="image">
                                    <el-image :src="item.picUrl" style="height:100%;width:100%"></el-image>
                                </div>
                                <div class="price-box">
                                    <span class="price">￥{{item.retailPrice}}</span>
                                    <span class="address">{{item.regionName}} {{item.entrepot}}</span>
                                </div>
                                <p class="name">{{item.name}} 材质{{item.material}}
                                    {{item.size!=null?(item.size.length>0?'规格'+item.size[0].value:''):''}} </p>
                                <p class="desc">{{item.place}}</p>
                            </div>
                        </router-link>
                    </div>
                </template>
                <NoDataFound v-else></NoDataFound>
            </div> -->
      <!-- 分页器 -->
      <div class="pager">
        <el-pagination background layout="prev, pager, next" :total="option.total" :page-size="option.pageSize" :current-page="option.currentPage" @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import FilterCondition from '@/components/FilterCondition.vue';
import NoDataFound from '@/components/NoDataFound.vue';

import {goodsList, categoryAll, newestLvdingPrice} from './api';
// import {getCartCount} from "../../layout/api";

export default {
  name: 'mall',
  data() {
    return {
      option: {
        //input框
        spec: '',
        manufacturer: '',
        clientName: '',
        categoryId: null,
        //select框
        moneyOrder: '',
        timeOrder: '',
        //分页
        currentPage: 1,
        total: 0,
        pageSize: 10
      },
      optionsTime: [
        {
          value: '',
          label: '默认'
        },
        {
          value: 'desc',
          label: '时间降序'
        },
        {
          value: 'asc',
          label: '时间升序'
        }
      ],
      optionsMoney: [
        {
          value: '',
          label: '默认'
        },
        {
          value: 'desc',
          label: '价格高排前面'
        },
        {
          value: 'asc',
          label: '价格低排前面'
        }
      ],
      filterConditionData: [
        {
          id: '',
          name: '',
          child: []
        }
      ],
      productList: [],
      lvDingPrice: {}
    };
  },
  computed: {
    getSearchItem() {
      return this.$store.getters['getterSearchItem'];
    }
  },
  components: {
    FilterCondition,
    NoDataFound
  },
  methods: {
    goodsList(type) {
      if (type) {
        this.option.name = this.$store.state.searchItem;
      } else if (this.option.name) {
        this.$delete(this.option, 'name');
      }
      goodsList(this.option).then(res => {
        if (res.errno == 0) {
          this.productList = res.data.goodsList;
          this.option.total = res.data.total;
        } else {
          this.productList = [];
          this.option.total = 0;
        }
      });
    },
    go(val) {
      this.option.categoryId = val;
      this.option.currentPage = 1;
      this.goodsList();
    },
    optionChangeGetList() {
      this.option.currentPage = 1;
      this.goodsList();
    },
    skip(id) {
      this.$router.push({
        name: 'goodsDetails',
        query: {
          id: id
        }
      });
    },
    notify(test) {
      this.$notify({
        title: '消息',
        message: test,
        type: 'info',
        duration: 1500
      });
    },
    // 触发分页器
    handleCurrentChange(val) {
      this.option.currentPage = val;
      this.goodsList();
    }
  },
  mounted() {
    this.option.categoryId = this.$route.query.cateId;
    newestLvdingPrice().then(res => {
      this.lvDingPrice = res.data;
    });
    this.goodsList();
    categoryAll(null).then(res => {
      this.filterConditionData = res.data;
      this.filterConditionData.unshift({id: undefined, name: '全部'});
    });
    // getCartCount().then(res => {
    //     this.$store.commit('setCarCount', res.data);
    // })
  },
  created() {
    this.option.categoryId = this.$route.query.cateId;
  },
  watch: {
    $route: function(val, oldVal) {
      if (val.name === 'Mall') {
        if (val.query.cateId) {
          this.option.categoryId = this.$route.query.cateId;
        } else {
          this.option.categoryId = '';
        }
        if (val.query.searchItem) {
          this.goodsList('search');
        }
        this.goodsList();
      }
    },
    '$store.state.searchItem': function(val) {
      console.log(val);
      this.goodsList('search');
    }
  }
};
</script>

<style lang="less" scoped>
.content {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;
  min-height: 340px;

  .box {
    width: calc(20% - 16px);
    padding: 0 8px;
    margin-bottom: 16px;

    .box-in {
      position: relative;
      background: #fff;
      padding: 13px 13px 20px 13px;

      .image {
        // width: 100%;
        height: 190px;
        overflow: hidden;

        img {
          width: 100%;
        }
      }

      .price-box {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
        line-height: 1;

        .price {
          font-size: 18px;
          color: #d91615;
          font-weight: bold;
        }

        .address {
          font-size: 12px;
          color: #666666;
        }
      }

      .name {
        color: #222222;
        font-size: 15px;
        margin-top: 12px;
        line-height: 18px;
        font-weight: bold;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .desc {
        color: #666666;
        font-size: 12px;
        margin-top: 8px;
        line-height: 1;
        text-decoration: underline;
      }
    }
  }
}
.goods-box {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;
  // min-height:610px;

  .goods-item {
    width: calc(20% - 16px);
    background: #fff;
    margin: 0 8px 16px 8px;
    padding: 14px;
    box-sizing: border-box;
    cursor: pointer;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    &:hover {
      box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.1);
    }
    .img-box {
      width: 100%;
      height: 190px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .name,
    .spec {
      font-size: 14px;
      color: #333333;
      text-align: justify;
      margin-top: 11px;
      line-height: 1;
    }
    .spec {
      margin-top: 5px;
    }
    .price {
      color: #ff4400;
      font-size: 18px;
      margin-top: 8px;

      span {
        font-size: 14px;
        margin-right: 2px;
      }
    }
  }
}
.pager {
  margin-top: 20px;
  margin-bottom: 50px;
  text-align: right;
}
</style>
